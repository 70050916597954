.tag {
	@apply bg-indigo-100 inline-flex items-center text-sm rounded mt-2 mr-1;

	& span {
		@apply ml-2 mr-1 leading-relaxed truncate max-w-xs;
	}

	& button {
		@apply w-6 h-8 inline-block align-middle text-gray-500 hover:text-gray-600 focus:outline-none;
	}
}

.tag.error {
	@apply border border-red-600 line-through;
}
