.form-label {
	@apply text-gray-500 select-none font-bold ml-0.5;
}

.form-input,
.form-textarea,
.form-select {
	@apply p-2 leading-normal border text-gray-700 bg-white font-sans rounded-l text-left appearance-none relative cursor-text;

	&:focus,
	&.focus {
		@apply border-zalandoOrange;
		box-shadow: 0 0 0 1px theme('colors.zalandoOrange');
	}

	&::placeholder {
		@apply text-gray-500 opacity-100;
	}
}

.form-input {
	@apply h-11;
}

.form-select {
	@apply pr-6;

	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAABGdBTUEAALGPC/xhBQAAAQtJREFUOBG1lEEOgjAQRalbGj2OG9caOACn4ALGtfEuHACiazceR1PWOH/CNA3aMiTaBDpt/7zPdBKy7M/DCL9pGkvxxVp7KsvyJftL5rZt1865M+Ucq6pyyF3hNcI7Cuu+728QYn/JQA5yKaempxuZmQngOwEaYx55nu+1lQh8GIatMGi+01NwBcEmhxBqK4nAPZJ78K0KKFAJmR3oPp8+Iwgob0Oa6+TLoeCvRx+mTUYf/FVBGTPRwDkfLxnaSrRwcH0FWhNOmrkWYbE2XEicqgSa1J0LQ+aPCuQgZiLnwewbGuz5MGoAhcIkCQcjaTBjMgtXGURMVHC1wcQEy0J+Zlj8bKAnY1/UzDe2dbAVqfXn6wAAAABJRU5ErkJggg==');
	background-size: 0.7rem;
	background-repeat: no-repeat;
	background-position: right 0.7rem center;
	cursor: pointer;

	&::-ms-expand {
		@apply opacity-0;
	}
}

.form-error {
	@apply text-red-700 text-sm;
}

.form-input.error,
.form-textarea.error,
.form-select.error {
	@apply border-red-600;

	&:focus {
		box-shadow: 0 0 0 1px theme('colors.red.600');
	}
}

.unit-select {
	& input {
		@apply hidden;
	}

	& input:checked+label {
		@apply bg-zalandoOrange text-gray-200;
	}

	& label {
		@apply bg-gray-300 text-gray-800 font-semibold py-2 px-4 cursor-pointer rounded mx-2;

		&:hover {
			@apply bg-gray-400
		}
	}

	&:first-child label {
		@apply ml-0;
	}

	&:last-child label {
		@apply mr-0;
	}
}

.form-checkbox {
	& label span {
		transition: background .2s,
			transform .2s;
	}

	&+label span:hover,
	&+label:hover span {
		transform: scale(1.2);
	}

	&:checked+label span {
		background-color: #3490DC;
		box-shadow: 0px 0px 0px 2px white inset;
	}

	&:checked+label {
		color: #3490DC;
	}
}
