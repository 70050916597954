@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	.hr {
		@apply border-t border-gray-300;
	}
}

@layer components {
	@import "components/buttons";
	@import "components/form";
	@import "components/nprogress";
	@import "components/tag";
}

@layer utilities {
	.terms {
		@apply text-sm;
		& h2 {
			@apply font-semibold mb-4 text-left;
		}

		& p {
			@apply mb-4 text-left;
		}

		& ul {
			@apply mb-4 list-disc list-outside;

			& li {
				@apply text-left ml-4;
			}
		}
	}
}
